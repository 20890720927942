export const getLabel = (value, options) => {
  return options.find( option => option.value === value)?.label || value
}

export const profileTypeOptionsStringInserts = [
  { value: 'AUTHOR', label: 'Author'},
  { value: 'GHOST_WRITER', label: 'Ghostwriter'},
  { value: 'PUBLISHER', label: 'Publisher' },
  { value: 'ILLUSTRATOR', label: 'Illustrator' },
  { value: 'ADVISOR', label: 'Advisor'},
  { value: 'EDITOR', label: 'Editor'},
  { value: 'AGENT', Label: 'Agent'},
]

export const profileTypeOptionsVerbStringInserts = [
  { value: 'AUTHOR', label: 'Authored'},
  { value: 'GHOST_WRITER', label: 'Written'},
  { value: 'PUBLISHER', label: 'Published' },
  { value: 'ILLUSTRATOR', label: 'Illustrated' },
  { value: 'ADVISOR', label: 'Advised'},
  { value: 'EDITOR', label: 'Edited'},
  { value: 'AGENT', Label: 'Represented'},
]

export const bookStatusOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'SUBMITTED', label: 'Submitted' },
  { value: 'PUBLISHED', label: 'Published' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'WITHDRAWN', label: 'Withdrawn' },
  { value: 'OTHER', label: 'Other' },
]

export const genreOptions = [
  { value: 'HISTORY', label: 'History' },
  { value: 'FICTION', label: 'Fiction' },
  { value: 'SCIENCE', label: 'Science' },
  { value: 'PHILOSOPHY', label: 'Philosophy' },
  { value: 'BIOGRAPHY', label: 'Biography' },
  { value: 'POETRY', label: 'Poetry' },
  { value: 'DRAMA', label: 'Drama' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'RELIGION', label: 'Religion' },
  { value: 'SELF_HELP', label: 'Self Help' },
  { value: 'HEALTH', label: 'Health' },
  { value: 'CHILDREN', label: 'Children' },
  { value: 'YOUNG_ADULT', label: 'Young Adult' },
  { value: 'SCIENCE_FICTION', label: 'Science Fiction' },
  { value: 'FANTASY', label: 'Fantasy' },
  { value: 'HORROR', label: 'Horror' },
  { value: 'ROMANCE', label: 'Romance' },
  { value: 'MYSTERY', label: 'Mystery' },
  { value: 'THRILLER', label: 'Thriller' },
  { value: 'SPIRITUALITY', label: 'Spirituality' },
  { value: 'NEW_AGE', label: 'New Age' },
  { value: 'SATIRE', label: 'Satire' },
  { value: 'ANTHOLOGY', label: 'Anthology' },
  { value: 'HUMOR', label: 'Humor' },
  { value: 'ADVENTURE', label: 'Adventure' },
  { value: 'CLASSICS', label: 'Classics' },
  { value: 'PARANORMAL', label: 'Paranormal' },
  { value: 'HISTORICAL_FICTION', label: 'Historical Fiction' },
  { value: 'HISTORICAL', label: 'Historical' },
  { value: 'DYSTOPIA', label: 'Dystopia' },
  { value: 'ACTION', label: 'Action' },
  { value: 'CRIME', label: 'Crime' },
  { value: 'SUSPENSE', label: 'Suspense' },
  { value: 'ANIMAL', label: 'Animal' },
  { value: 'COOKBOOK', label: 'Cookbook' },
  { value: 'DIET', label: 'Diet' },
  { value: 'JOURNAL', label: 'Journal' },
  { value: 'GUIDE', label: 'Guide' },
  { value: 'LGBT', label: 'LGBT' },
  { value: 'LIFE_STYLE', label: 'Life Style' },
  { value: 'MEMOIR', label: 'Memoir' },
  { value: 'PARENTING', label: 'Parenting' },
  { value: 'POLITICS', label: 'Politics' },
  { value: 'REFERENCE', label: 'Reference' },
  { value: 'RELATIONSHIP', label: 'Relationship' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'TECHNICAL', label: 'Technical' },
  { value: 'TRANSLATION', label: 'Translation' },
  { value: 'YOUTH', label: 'Youth' },
  { value: 'OTHER', label: 'Other' },
]