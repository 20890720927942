import React, { useState, useContext } from 'react';
import useFetch from 'use-http'

import { MainContext } from '../../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { bookStatusOptions, genreOptions } from '../../Util/constants';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'
import { useNavigate } from 'react-router-dom';

export default function BookCreateModal(props) {
  const { jwt } = useContext(MainContext);
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('DRAFT')
  const [genre, setGenre] = useState('OTHER')
  const [description, setDescription] = useState('')
  const [isbn10, setIsbn10] = useState('')
  const [isbn13, setIsbn13] = useState('')
  const [year, setYear] = useState(2023)

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const createBook = async () => {
    setError('')
    let args = {
      title,
      status,
      genre,
      description,
    }
    if (year) { args['year'] = year }
    if (isbn10) { args['isbn10'] = isbn10 }
    if (isbn13) { args['isbn13'] = isbn13 }
    await post('/api/book', args);
    if (response.ok) {
      props.onClose()
      navigate('/createBook', { replace: true })
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
              <AutoStoriesIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Add a book
            </Typography>
          </Center>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              margin="normal"

              fullWidth
              value={genre}
              labelId="genre-helper-label"
              id="genre-select-helper"
              label="Genre"
              select
              onChange={e => setGenre(e.target.value)}>
              {genreOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              value={status}
              label="Status"
              select
              onChange={e => setStatus(e.target.value)}>
              {bookStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              error={year && (year < 1800 || year > 2100)}
              helperText={year && (year < 1800 || year > 2100) ? "Invalid year" : ''}
              id="year"
              label="Year"
              name="year"
              value={year}
              onChange={e => setYear(e.target.value)}
            />
            } */}
            {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              id="isb10"
              label="Isbn10"
              name="isb10"
              value={isbn10}
              onChange={e => setIsbn10(e.target.value)}
            />
            }
            {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              id="isbn13"
              label="Isbn13"
              name="isbn13"
              value={isbn13}
              onChange={e => setIsbn13(e.target.value)}
            />
            }
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={4}
              id="description"
              label="Description"
              name="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <Alert style={{ visibility: error ? 'visible' : 'hidden' }} severity="error">
              {error}
            </Alert>

          </Box>
          <Center>
            <Stack direction="row" spacing={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => createBook()}>
                Add
              </Button>
              <Button
                type="submit"
                fullWidth
                color="error"
                variant="contained"
                onClick={props.onClose}>
                Cancel
              </Button>
            </Stack>
          </Center>

        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}