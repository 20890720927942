import React, { useState } from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link'

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'
import {getLabel, bookStatusOptions, genreOptions } from '../../Util/constants';

export default function BookViewModal(props) {
  const [error] = useState('')
  const [expanded, setExpanded] = useState(false)

  return (
    <Dialog open={props.open} onClose={props.onClose} PaperProps={{sx: {width: { xs: '300px', sm: '500px', md: '600px'}}}}>
      <DialogContent sx={{ boxSizing: 'border-box' }}>
        <DialogContentText id="alert-dialog-description" style={{alignItems: 'center'}}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
              <AutoStoriesIcon />
            </Avatar>

          </Center>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Center>
              <img style={{ height: 300, width: 'auto' }} src={props.book.cover_pic} />
            </Center>
            <Center>
              <Typography sx={{ mt: 3, whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflowWrap: 'anywhere', width: '100%'}} component="h1" variant="h3" >
                {props.book.title}
              </Typography>
            <Typography component="h1" variant="h6">
              Genre: {getLabel(props.book.genre, genreOptions)}
            </Typography>
            <Typography component="h1" variant="h6">
            Status: {getLabel(props.book.status, bookStatusOptions)}
            </Typography>
            </Center>
            <Typography component="h1" variant="h6" sx={{ mt: 3, whiteSpace: 'pre-wrap', overflowWrap: 'anywhere', width: '100%', boxSizing: 'border-box'}}>
            {expanded ? props.book.description : ( props.book?.description?.length > 300 ? props.book?.description?.substring(0, 300) + '...' : props.book?.description)}
            {props.book?.description?.length > 300 && <Link component="button" variant="body2" onClick={(e) => {e.preventDefault(); setExpanded(!expanded)}} sx={{ display: 'block', mt: 1}}>{expanded ? '[Less]' : '[More]'}</Link>}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}